<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3" v-if="!isEdit">Add New News</h4>
          <h5 class="mb-3" v-if="isEdit">Edit News</h5>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <b-form-input v-model="blogPostForm.title" id="title" placeholder="Enter Blog Title" required>
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <b-form-input v-model="blogPostForm.sub_title" id="subTitle" placeholder="Enter Blog Sub Title"
                      required>
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <div class="mb-3">
              <label for="categories" class="font-weight-bolder">Categories :</label>
              <b-form-select v-model="blogPostForm.category_id" @change="tagsFilter" required>
                <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.name
                }}</b-form-select-option>
              </b-form-select>
            </div>
            <!-- Tags -->
            <template v-if="blogPostForm.category_id.length !== 0">
              <div class="mb-3">
                <label for="tags" class="font-weight-bolder">Tags :</label>
                <b-form-checkbox-group v-model="selectedTags" buttons class="d-flex  align-items-center"
                  @change="changeArrToString">
                  <b-form-checkbox v-for="t in tagsFilterArr" :key="t.id" :value="t.id" size="sm"
                    class="rounded-pill mr-2 tag" button button-variant="outline-primary">
                    {{ t.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </template>

            <!-- Description -->
            <div class="mb-3">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor v-model="blogPostForm.description"></vue-editor>
            </div>

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col>
                <label for="author" class="font-weight-bolder">Author :</label>
                <b-form-input v-model="blogPostForm.author" id="author" placeholder="Enter Author's Name" required>
                </b-form-input>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Publish :</label>
                  <b-form-checkbox v-model="publish" @change="toggle(publish)" name="check-publish-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- Posted Date -->
            <b-row>
              <b-col>
                <div>
                  <label for="posted_at" class="font-weight-bolder">Posted Date :</label>
                  <b-form-datepicker id="posted_at" v-model="blogPostForm.posted_at" class="mb-2"
                    required></b-form-datepicker>
                </div>
              </b-col>

              <!-- Photos -->
              <b-col v-if="!isEdit" class="mb-4">
                <label for="contact-info" class="font-weight-bolder">Photos :</label>
                <div>
                  <MyPhotoUpload @getPhotoId="uploadPhoto" />
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button type="button" variant="outline-primary" @click="() => router.go(-1)" class="col-md-10 col-lg-8">
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="!isEdit">
              <b-button variant="primary" class="col-md-10 col-lg-8" type="submit" :disabled="!isAllUploaded">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Submit</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="isEdit">
              <b-button type="button" variant="primary" @click="updateData(blogPostForm.id)" class="col-md-10 col-lg-8">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Update</span>
              </b-button>
            </div>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
// import MapLocationSelector from 'vue-latlng-picker';
import Table from "../components/Table.vue";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import {
  ref,
  reactive,
} from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    // MapLocationSelector,
    VueEditor,
    Table,
    MyPhotoUpload,
  },
  setup(props, { emit }) {
    const { router } = useRouter();
    const routerQuery = router.app._route.query;
    const spinner = ref(false);
    const checked = ref(true);
    const publish = ref(true);
    const isAllUploaded = ref(false);
    const categories = ref(JSON.parse(routerQuery.categories));
    const tags = ref(JSON.parse(routerQuery.tags));
    const tagsFilterArr = ref([]);
    const isEdit = ref(JSON.parse(routerQuery.isEdit));
    const mulImgs = ref([]);
    const selectedTags = ref([]);
    const blogPostForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      author: "",
      status: "publish",
      posted_at: "",
      tags: [],
      photos: [],
    });

    //Datas Fetching
    //Functions
    // tags filter
    const tagsFilter = () => {
      tagsFilterArr.value = tags.value.filter(tag => {
        return blogPostForm.category_id == tag.category_id;
      })
    }
    // service categories
    const changeArrToString = () => {
      // blogPostForm.tags = JSON.stringify(selectedTags.value);
      blogPostForm.tags = selectedTags.value;
    };
    const toggle = (c = checked.value, p = publish.value) => {
      p
        ? (blogPostForm.status = "publish")
        : (blogPostForm.status = "unpublish");
    };
    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      let multiplePhotos = mulImgs.value;
      // blogPostForm.photos = JSON.stringify(multiplePhotos);
      blogPostForm.photos = multiplePhotos;

    };
    // Clear Datas
    const clearDatas = () => {
      blogPostForm.title = "";
      blogPostForm.category_id = "";
      blogPostForm.sub_title = "";
      blogPostForm.description = "";
      blogPostForm.author = "";
      blogPostForm.posted_at = "";
      blogPostForm.status = "publish";
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "news",
          payload: blogPostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        });
    };

    // Data Editing
    if (isEdit.value) {
      const editServiceData = () => {
        let editDatas = JSON.parse(routerQuery.editDatas);
        // console.log(editDatas)
        blogPostForm.id = editDatas.id;
        blogPostForm.title = editDatas.title;
        blogPostForm.category_id = editDatas.category_id;
        blogPostForm.sub_title = editDatas.sub_title;
        blogPostForm.description = editDatas.description;
        blogPostForm.posted_at = editDatas.posted_at;
        blogPostForm.author = editDatas.author;

        editDatas.tags.forEach(tag => {
          selectedTags.value = [...selectedTags.value, tag.id]
        })
        tagsFilter()
      };
      editServiceData();
    }
    const updateData = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "news",
          payload: blogPostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        });
    };

    // Close Function
    const close = () => {
      isEdit.value = false;
      clearDatas();
    };

    // All Uploaded
    const allUploaded = () => {
      if (blogPostForm.title &&
        blogPostForm.sub_title &&
        blogPostForm.category_id &&
        blogPostForm.description &&
        blogPostForm.author &&
        blogPostForm.posted_at &&
        // blogPostForm.tags.length !== 0 &&
        blogPostForm.photos.length !== 0) {
        isAllUploaded.value = true;
      }
    }

    return {
      spinner,
      isEdit,
      checked,
      publish,
      categories,
      tags,
      tagsFilterArr,
      tagsFilter,
      handleSubmit,
      toggle,
      uploadPhoto,
      selectedTags,
      changeArrToString,
      blogPostForm,
      selectedTags,
      updateData,
      close,
      router,
      isAllUploaded,
      allUploaded,
    };
  },
};
</script>
<style scoped>
.tag {
  padding: 0.5rem 1.5rem;
  flex: 0 0 auto;
}
</style>